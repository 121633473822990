import React from "react"
import { rhythm } from "../utils/typography"
import { Link } from "gatsby"
import moment from "moment"

const ListItem = ({ node }) => {
  const title = node.frontmatter.title || node.fields.slug
  return (
    <article key={node.fields.slug}>
      <h3
        style={{
          marginBottom: rhythm(1 / 4),
        }}
      >
        <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
          {title}
        </Link>
      </h3>
      <small
        style={{
          "text-align": "right",
          display: "block",
        }}
      >
        {node.frontmatter.date &&
          moment(node.frontmatter.date).format("YYYY/MM/DD")}
      </small>
      <section>
        <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
      </section>
    </article>
  )
}

export default ListItem
