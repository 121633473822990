import React from "react"
import Layout from "./layout"
import Bio from "./bio"
import SEO from "./seo"
import ListItem from "./list_item"

const List = ({
  posts,
  location,
  siteTitle,
  pageCount = 20,
  currentPage = 1,
  pageTitle,
}) => {
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={pageTitle || `All posts ${currentPage}`} />
      {posts.map(({ node }) => (
        <ListItem node={node} />
      ))}

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {currentPage > 1 && (
              <a href={`/blog/${currentPage - 1}/`}>← previous</a>
            )}
          </li>
          <li>
            {currentPage < pageCount && (
              <a href={`/blog/${currentPage + 1}/`}>next →</a>
            )}
          </li>
        </ul>
      </nav>

      <Bio />
    </Layout>
  )
}

export default List
